<template>
  <div>
    <div class="wrap-banner">
      <img
        class="banner"
        src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/banners/banner02.png"
      />
    </div>
    <div class="wrap-content">
      <van-row type="flex" justify="center">
        <van-col class="wrap-form" span="22">
          <!-- <div>供养种类</div> -->
          <van-row type="flex" justify="space-between">
            <van-col class="left" offset="1" span="6">供养种类</van-col>
            <van-col span="14">
              <div class="wrap-right">
                <div
                  :class="abc == index ? 'rightred':'rightdefault'"
                  v-for="(item,index) in production"
                  :key="index"
                  @click="getid(index)"
                >{{item.productname}}</div>
              </div>
            </van-col>
            <van-col span="1"></van-col>
          </van-row>
          <!-- <div>供养规格</div> -->
          <van-row
            v-show="false?this.abc !==4:this.abc==4"
            class="content-form"
            type="flex"
            justify="space-between"
          >
            <van-col class="left" offset="1" span="6">供养规格</van-col>
            <van-col class="right" span="14">
              <van-dropdown-menu active-color="#a40f37">
                <van-dropdown-item @change="change" v-model="value" :options="option1" />
              </van-dropdown-menu>
            </van-col>
            <van-col span="1"></van-col>
          </van-row>
          <div></div>

          <van-form @submit="onSubmit" ref="formData">
            <!-- <div>供养金额</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">供养金额</van-col>
              <van-col class="right" span="14">
                <van-field
                  v-model="price"
                  placeholder="请输入金额（必填）"
                  :rules="[{ pattern, message: '请输入正确金额' }] "
                  :readonly="this.status?false:'readonly'"
                />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>功德芳名</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">功德芳名</van-col>
              <van-col class="right" span="14">
                <van-field v-model="name" placeholder="请输入姓名（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>联系电话</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">联系电话</van-col>
              <van-col class="right" span="14">
                <van-field v-model="phone" placeholder="请输入电话（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>祈福心愿</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">祈福心愿</van-col>
              <van-col class="wished" span="14">
                <div>
                  <van-field
                    v-model="wish"
                    rows="3"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入祈愿内容（选填）"
                    show-word-limit
                    label-align="right"
                    colon
                  />
                </div>
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
          </van-form>
          <!-- <div>供养按钮</div> -->
          <van-row type="flex" justify="center">
            <van-col span="9" class="btn">
              <van-button color="#bb5548" round size="large" @click="generate">供养</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
    <van-overlay :show="show" />
  </div>
</template>

<script>
import { ready } from "@/utils/wxutils.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      status: true,
      abc: 0,
      value: 0,
      show: false,
      option1: [
        {
          text: "",
          value: "",
        },
      ],
      productid: "",
      specid: "",
      specs: [],
      price: "",
      name: "",
      phone: "",
      wish: "",
      production: [],
      pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/,
    };
  },
  created() {
    this.getprod();
  },
  computed: {
    changecolor(abcd) {
      if (abcd == this.abc) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getprod() {
      this.$api.offer.getofferprod().then((res) => {
        this.production = res.data.data;
        this.productid = this.production[0].id;
        this.specs = this.production[0].specs;
        this.price = this.specs[0].price;
        this.specid = this.specs[0].id;
        this.getspecs();
      });
    },
    getid(index) {
      this.abc = index;
      if (this.abc == 4) {
        this.status = false;
      } else {
        this.status = true;
      }
      this.productid = this.production[index].id;
      console.log(index);
      this.specs = this.production[index].specs;
      console.log(this.specs);
      this.getspecs();
      this.value = 0;
      this.price = this.specs[this.value].price;
      this.specid = this.specs[this.value].id;
    },
    getspecs() {
      let test = this.specs;
      this.option1 = [];
      for (var i = 0; i < test.length; i++) {
        var obj = {};
        obj.text = test[i].specname;
        obj.value = i;
        this.option1.push(obj);
        console.log(obj.value);
        console.log(this.option1);
      }
    },
    change(v) {
      console.log(v);
      this.price = this.specs[this.value].price;
      this.specid = this.specs[this.value].id;
    },
    onSubmit() {
      this.show = true;
      let that = this;
      this.$api.offer
        .generateorder(
          this.productid,
          this.specid,
          this.name,
          this.phone,
          this.wish,
          this.price,
          console.log(this.productid),
          console.log(this.specid)
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            ready(["chooseWXPay"])
              .then((res2) => {
                console.log(res2);
                wx.chooseWXPay({
                  timestamp: res.data.data.timestamp,
                  nonceStr: res.data.data.nonceStr,
                  package: res.data.data.package,
                  signType: res.data.data.signType,
                  paySign: res.data.data.paySign,
                  success: function () {
                    let orderno = res.data.data.orderno;
                    let retry = 1;
                    let S = setInterval(function () {
                      if (retry > 5) {
                        clearInterval(S);
                        that.$toast.fail("未获取到支付结果");
                        that.show = false;
                        return;
                      } //查询订单是否支付
                      that.$api.offer.ensure(orderno).then((res) => {
                        retry += 1;
                        console.log(res);
                        if (res.data.status == 1) {
                          clearInterval(S);
                          that.show = false;
                          that.$toast.success("支付成功");
                          that.$router.push({
                            path: "/center/offers",
                          });
                        }
                      });
                    }, 2000); //console.log(result);
                  },
                  cancel: function () {
                    that.show = false;
                  },
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    generate() {
      this.$refs.formData.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  &-right {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 0.4rem;
  }
}
</style>